import './Blog.css'
import './Footer.css'
import { useState , useEffect} from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

export default function Blog(){
    const {id} = useParams()
    const[blogDetails, setBlogDetails] = useState(false)
    const[isError, setError]= useState(false)
    const [isLoading , setLoading] = useState(false)
    useEffect(()=>{
        const fetchDetails = async ()=>{
            try{
                setLoading(true)
                await axios.get(`https://us-central1-website-2f4c5.cloudfunctions.net/app/response/Blogs/${id}`)
                .then(res=>{
                    setLoading(false)
                    if(res.status === 200){
                    setBlogDetails(res.data)
                    }else{
                        setError(true)
                    }
                })
            }catch{
                setLoading(false)
                setError(true)
            }
        }
        fetchDetails()
    },[id])

    return(
        <div className='blog_cont'>
            <Helmet>
                <title>{`markupDevSolutions(A.M)-${id}`}</title>
                <meta name="description" content={blogDetails.description}/>
                <meta name="google-adsense-account" content="ca-pub-3619400689446319"/>
                <meta name="keywords" content={id}/>
            </Helmet>
            {isError && <div className='blogError'> <p>oops!! nothing to show.</p> <Link to="/blog" id='blog_back'>Go Back</Link></div>}
            {isLoading && <div id='blog_Loading'></div>}
            {blogDetails&& <img src={blogDetails.image} alt='' id='blog_image' />}
        <div className='blog_details' dangerouslySetInnerHTML={{__html: blogDetails.content}}/>
        {blogDetails && <div className='footer'>
            <ul>
            <li><a href='https://www.facebook.com/profile.php?id=100083372384528'> <img src={`${process.env.PUBLIC_URL}/images/facebookBlack.png`} alt='fb'/> </a></li>
            <li><a href='https://www.upwork.com/freelancers/~019e9ee0fe1e2a467c'> <img src={`${process.env.PUBLIC_URL}/images/upworkBlack.png`} alt='up'/> </a></li>
            <li><a href='https://www.youtube.com/@markUpDevSolutions'> <img src={`${process.env.PUBLIC_URL}/images/youtubeBlack.png`} alt='yt'/></a></li>
            <li><a href='https://www.linkedin.com/in/austine-mark-abb7282aa'><img src={`${process.env.PUBLIC_URL}/images/linkedinBlack.png`} alt='ln'/></a></li>
            </ul>
            <p>©2024 - austine mark</p>
            </div>}
        </div>
    )
}
