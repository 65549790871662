import React from "react";
import './App.css'
import Hom from "./components/Hom";
import Tools from "./components/Tools";
import { BrowserRouter as Router,Routes, Route} from 'react-router-dom';
import Contact from './components/Contact'
import About from './components/About'
import Proj from "./components/Proj";
import Terms from './components/Terms';
import Blogs from "./components/Blogs";
import Blog from "./components/Blog";
import NotFound from "./components/NotFound";
import Testimonials from "./components/Testimonials";

export default function App(){
    return(
        <Router>
        <>
        <Tools/>
        <Routes>
        <Route path="/" exact Component={Hom} />
        <Route path="/projects" Component={Proj}/>
        <Route path="/about" Component={About} />
        <Route path="/contact" Component={Contact} />
        <Route path="/blog" Component={Blogs}/>
        <Route path="/terms" Component={Terms} />
        <Route path = "/blog/:id" Component={Blog}/>
        <Route path="/testimonials" Component={Testimonials}/>
        <Route path="*"  Component={NotFound}/>
        </Routes>
        </>
        </Router>
    )
}