import './Contact.css'
import axios from "axios";
import { useState } from "react";
import { Helmet } from 'react-helmet';


export default function Contact(){
    const[formData, setFormData]= useState({
        name: "",
        email:"",
        message: "",
    })
    const[isLoading, setLoading] = useState(false)
    const[reStatus, setStatus] =useState(false)

    const handle_change = (e)=>{
        const name = e.target.name
        const value = e.target.value
        setFormData({...formData,[name]:value})
    }
    const handle_submit = async(e)=>{
        e.preventDefault()
        console.log(formData)
        try{
            setStatus('sending Message....')
            setLoading(true)
            await axios
            .post('https://us-central1-website-2f4c5.cloudfunctions.net/app/message/email', formData)
            .then((resp)=>{
                setLoading(false)
                if(resp.status === 200){
                    setStatus('Message sent successfully you will recieve an email from me...')
                }else{
                    setStatus('Internal server Error try again later....!!!')
                }
                setTimeout(()=>{
                    setStatus(false)
                },5000)
            })
            } catch (error) {
            console.error('Error sending message:', error);
            setLoading(false)
            setStatus('Error sending Message please  check your internet connection...')
            setTimeout(()=>{
                setStatus(false)
            },5000)
}
    }
        return(
            <div className='contact_cont'>
             <Helmet>
                <title>{"markupDevSolutions(A.M)-contact"}</title>
                <meta name="description" content="markupDevSolutions blogs" />
                <meta name="google-adsense-account" content="ca-pub-3619400689446319"/>
                <meta name="keywords" content="contact, form, hire," />
            </Helmet>
            <form className='contact_form' onSubmit={handle_submit} >
                <h2>Contact</h2>
                <label id='name_lb'>name: </label>
                    <input type='text' name='name'  value={formData.name} placeholder='firstname lastname'
                    onChange={handle_change} minLength={5} maxLength={150} required/>
                
                <label id='email_lb'>email: </label> 
                    <input type='email' name='email' value={formData.email} placeholder='example@example.com'
                    onChange={handle_change} minLength={12} maxLength={320} required/>
                
                <label id='message_lb'>message: </label>
                    <textarea name='message'  value={formData.message} placeholder='Enter the message maximum characters 1500'
                    onChange={handle_change} minLength={10} maxLength={1000} required></textarea>
                
                <input  type='checkbox' required/> <span>I have read and agreed with the terms and conditions</span>
                <input type='submit' value={'Submit'}></input>
                <p>!Use this form to send only relevant messages if it doesn't work use other alternatives provided</p>
            </form>
            {reStatus &&<div id='note_cont'>
               {isLoading && <div></div>}
                <p>{reStatus}</p>
           </div> }
           <div className='other_contact'>
             <p>You can also Contact me through whatsapp +254111343665 or  through the links. </p>
            <ul>
                <li><a href='https://www.facebook.com/profile.php?id=100083372384528'> <img src={`${process.env.PUBLIC_URL}/images/facebookWhite.png`} alt='fb'/> </a></li>
                <li><a href='https://www.upwork.com/freelancers/~019e9ee0fe1e2a467c'> <img src={`${process.env.PUBLIC_URL}/images/upworkWhite.png`} alt='up'/> </a></li>
                <li><a href='https://www.youtube.com/@markUpDevSolutions'> <img src={`${process.env.PUBLIC_URL}/images/youtubeWhite.png`} alt='yt'/></a></li>
                <li><a href='https://www.linkedin.com/in/austine-mark-abb7282aa'><img src={`${process.env.PUBLIC_URL}/images/linkedinWhite.png`} alt='ln'/></a></li>
            </ul>
           </div>
           </div>
        )
}