import './Others.css'
import './Footer.css'
import { Helmet } from 'react-helmet'

export default function About(){
    return(
        <div className='about_cont'>
            <Helmet>
                <title>{"markupDevSolutions(A.M)-about"}</title>
                <meta name="description" content="markupDevSolutions about" />
                <meta name="google-adsense-account" content="ca-pub-3619400689446319"/>
                <meta name="keywords" content="about" />
            </Helmet>
            <div className='about_content'>
            <h2>About Me</h2>
            <p>{'>>> '}Hello, I'm Austine Mark, I'm a passionate  and dedicated web developer with a skill for 
                crafting seamless web applications and mobile solutions. Am an expertise in both front-end and back-end technologies, 
                I specialize in building robust and user-friendly experiences. 
                Am currently a computer science student for more details contact me.<br/> </p>
            <h3>What I do</h3>
            <p>
            {'>>> '}I  Develop From single-page applications to fully-fledged web solutions, 
            harnessing the power of React framework and Express to bring your ideas to life on the web.<br/>
            {'>>> '}I also  design and develop mobile applications that not only meet but exceed user expectations,
            providing engaging and intuitive experiences.<br/>
            {'>>> '}I also have diverse skills in python programming 
            and will use it to bring various brilliant ideas into life and life hacking projects.
            </p>
            <h3> My services</h3>
            <ul>
               <li>Front-end services: Html, css, JavaScript, React, Vite</li>
               <li>Small Backe-end: node.js, express.js</li>
               <li>Lightweight Andoid app: Javascript, React-native, expo </li>
               <li>Bug fixing</li>
             </ul>
            <h3>Conclusion</h3>
            <p>
            {'>>> '}I have hands-on experience in developing a variety of projects, 
            ranging from single-page applications to fully-featured web and mobile applications. 
            My projects showcase my commitment to delivering solutions that are not only functional but also user-friendly.<br/>
            {'>>> '}I am driven by a passion for problem-solving and a commitment to continuous learning. 
            Whether it's implementing user_friendly  frontend designs or architecting scalable backend systems.<br/>
            {'>>> '}I am dedicated to delivering high-quality software solutions.
            Let's collaborate to turn your ideas into reality! 
            Feel free to explore my portfolio and get in touch for any inquiries or potential projects.
            </p>
            </div>
            <div className='footer'>
                <ul>
                <li><a href='https://www.facebook.com/profile.php?id=100083372384528'> <img src={`${process.env.PUBLIC_URL}/images/facebookBlack.png`} alt='fb'/> </a></li>
                <li><a href='https://www.upwork.com/freelancers/~019e9ee0fe1e2a467c'> <img src={`${process.env.PUBLIC_URL}/images/upworkBlack.png`} alt='up'/> </a></li>
                <li><a href='https://www.youtube.com/@markUpDevSolutions'> <img src={`${process.env.PUBLIC_URL}/images/youtubeBlack.png`} alt='yt'/></a></li>
                <li><a href='https://www.linkedin.com/in/austine-mark-abb7282aa'><img src={`${process.env.PUBLIC_URL}/images/linkedinBlack.png`} alt='ln'/></a></li>
                </ul>
                <p>©2024 - austine mark</p>
            </div>
        </div>
    )
}