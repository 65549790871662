export const setNews = (news)=>({
    type:'SET_NEWS',
    payload:news
})

export const setBlogs = (blogs)=>({
    type:'SET_BLOGS',
    payload:blogs
})

export const setProjects = (projects)=>({
    type:'SET_PROJECTS',
    payload:projects
})

export const setLearn = (learn)=>({
    type:'SET_LEARN',
    payload:learn
})
export const setTestims = (testims)=>({
    type:'SET_TESTIMS',
    payload:testims
})
