import React from "react";
import './Others.css'
import { Link } from "react-router-dom";

export default function NotFound(){
    return(
        <div className="notFound_cont">
            <img src={`${process.env.PUBLIC_URL}/images/notfound.png`} alt="404 PAGE NOT FOUND"/>
            <Link to="/" id="notFound_link">Go back home</Link>
        </div>
    )
}