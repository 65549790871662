import './Blog.css'
import './Footer.css'
import { useEffect,useState } from 'react'
import axios from 'axios'
import { setBlogs } from './actions'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'
import { Helmet } from 'react-helmet'


export function Blogs({blogs, setBlogs}){
    const [isLoading ,setLoading] = useState(false)
    const[isError, setError]= useState(false)
    useEffect(()=>{
        const fetchList = async ()=>{
            try{
                setLoading(true)
                await axios.get('https://us-central1-website-2f4c5.cloudfunctions.net/app/response/Blogs')
                .then(res=>{
                    if(res.status === 200){
                    const data = res.data
                    setBlogs(data)
                    }else{
                        setError(true)
                    }
                })
            }catch(error){
                console.error(error)
                setError(true)
            }
            finally{setLoading(false)}
        }
        if(!blogs.length){
        fetchList()
        }
    },[blogs, setBlogs])
    
    return(
        <div  className='blogList_cont'>
             <Helmet>
                <title>{"markupDevSolutions(A.M)-blogs"}</title>
                <meta name="description" content="Get the learning and  latest tech related contents anda other important features from markupDevSolutions blog" />
                <meta name="keywords" content={blogs.map(blog=>blog.title).join(',')} />
                <meta name="google-adsense-account" content="ca-pub-3619400689446319"/>
            </Helmet>
            <h1>My blogs</h1>
            {isError && <p id='blog_error'>&#128557; OOPs nothing found!!</p>}
            {isLoading && <div id='list_Loading'></div>}
            <ul className='blog_list'>
                {blogs && blogs.map(blog=>(
                    <li key={blog.id}>
                        <Link className='blogList_link' to={`/blog/${blog.id}`}>
                        <div>
                            <img src={blog.image} alt='me_image' />
                            <h3>{blog.title}</h3>
                            <p>{blog.description}</p>
                        </div>
                        </Link>
                    </li>
                ))}
            </ul>
            {blogs && <div className='footer'>
                <ul>
                <li><a href='https://www.facebook.com/profile.php?id=100083372384528'> <img src={`${process.env.PUBLIC_URL}/images/facebookBlack.png`} alt='fb'/> </a></li>
                <li><a href='https://www.upwork.com/freelancers/~019e9ee0fe1e2a467c'> <img src={`${process.env.PUBLIC_URL}/images/upworkBlack.png`} alt='up'/> </a></li>
                <li><a href='https://www.youtube.com/@markUpDevSolutions'> <img src={`${process.env.PUBLIC_URL}/images/youtubeBlack.png`} alt='yt'/></a></li>
                <li><a href='https://www.linkedin.com/in/austine-mark-abb7282aa'><img src={`${process.env.PUBLIC_URL}/images/linkedinBlack.png`} alt='ln'/></a></li>
                </ul>
                <p>©2024 - austine mark</p>
            </div>}
        </div>
    )
}

 
const mapStateToProps = (state) => ({
    blogs: state.blogs,
});
  
const mapDispatchToProps = {
    setBlogs,
};
export default connect(mapStateToProps, mapDispatchToProps)(Blogs);