import React from "react";
import './Others.css'
import { Helmet } from "react-helmet";

export default function Terms(){
    return(
        <div className="terms_cont">
            <Helmet>
                <title>{"markupDevSolutions(A.M)-terms and conditions"}</title>
                <meta name="description" content="markupDevSolutions terms and conditions" />
                <meta name="keywords" content="terms,conditions" />
                <meta name="google-adsense-account" content="ca-pub-3619400689446319"/>
            </Helmet>
            <div className="terms_content">
                <h2> These are the Terms and Conditions</h2>

                <h4>Acceptance of Terms</h4>
                <p>By accessing and using this website, you agree to be bound by these terms and conditions. 
                If you do not agree with any part of these terms, please do not use the website. <br/></p>
                
                <h4>Purpose of Communication</h4>
                <p>The messaging feature on this website is intended for inquiries related to My services, 
                    portfolio, and potential collaboration opportunities.
                    Any form of unsolicited marketing, spam, or inappropriate content is strictly prohibited.<br/></p>

                <h4>Contact form usage</h4>
                <p>Users may use the provided contact form on the website to send messages or inquiries to [Your Name]. 
                    By submitting a message through the contact form, users agree to comply with these terms. And remember Use the
                    form to send relevant messages. <br/></p>
                
                <h4>Response Time</h4>
                <p>I will make reasonable efforts to respond to messages in a timely manner, but there is no guaranteed response time.
                Urgent or time-sensitive inquiries should be directed through alternative contact methods Such as whatsapp number.<br/></p>
                
                <h4>Termination of Communication</h4>
                <p> I have the right to terminate communication with any user who violates these terms or engages in inappropriate behavior.
                Users also have the option to block or report any suspicious or unwanted messages. <br/></p>

                <h4>Limitation of Liability</h4>
                <p> Austine will not be liable to you (whether under the law of contract, the law of torts, or otherwise) 
                in relation to the contents of, or use of, or otherwise in connection with, this website for any direct, indirect, consequential, 
                special, or incidental damages. <br/></p>
                
                <h4>Use of Website</h4>
                <p> You agree to use this website for lawful purposes and in a manner consistent with all applicable laws and regulations. 
                    You are prohibited from engaging in any activity that may damage, disable, or impair the website or interfere with its functionality. <br/></p>

                <h4>Portfolio Projects</h4>
                <p> The projects showcased in the portfolio are the original work of Austine. 
                    Clients and employers associated with the projects have granted permission for their inclusion in the portfolio. 
                Unauthorized use or reproduction of these projects is strictly prohibited. <br/></p>
                
                <h4>Intellectual Property</h4>
                <p> All content on this website, including but not limited to text, graphics, logos, images, links , and software, 
                    is the property of Austine and is protected by copyright laws.
                    You may not reproduce, modify, distribute, or republish any content without prior written consent. <br/></p>
                
                <h4>Privacy and Data Security</h4>
                <p>Do not share sensitive personal information, financial details, or any confidential data through the messaging system.
                We are  committed to protecting user privacy. You will only share needed details through a private chat with Austine. <br/></p>
                
                <h4>Linking to My Website</h4>
                <p>You are welcome to link to  My portfolio website, 
                provided the link does not imply endorsement or approval by Austine of my  website and its content. <br/></p>
                
                <h4>Navigation</h4>
                <p>Links on this website are provided for navigation purposes,
                allowing users to access specific sections, projects, or external resources related to My portfolio. <br/></p>
                
                <h4>External Websites</h4>
                <p>Some links may direct users to external websites or third-party resources. 
                    I am not responsible for the content, accuracy, or security of these external sites. <br/></p>
                
                <h4>No Guarantee of Availability</h4>
                <p> I  doesn't guarantee the availability of linked content, 
                especially for external websites or resources that may be subject to changes beyond My control. <br/></p>
                
                <h4>Changes to Links</h4>
                <p> I may update or modify links on this website without prior notice.
                    Users are encouraged to report any issues or concerns regarding links. <br/></p>

                <h4>Changes to Terms</h4>
                <p>I Austine may update or modify these messaging terms without prior notice. Users are encouraged to review the terms periodically. <br/></p>
            </div>
            <div className='footer'>
                <ul>
                <li><a href='https://www.facebook.com/profile.php?id=100083372384528'> <img src={`${process.env.PUBLIC_URL}/images/facebookBlack.png`} alt='fb'/> </a></li>
                <li><a href='https://www.upwork.com/freelancers/~019e9ee0fe1e2a467c'> <img src={`${process.env.PUBLIC_URL}/images/upworkBlack.png`} alt='up'/> </a></li>
                <li><a href='https://www.youtube.com/@markUpDevSolutions'> <img src={`${process.env.PUBLIC_URL}/images/youtubeBlack.png`} alt='yt'/></a></li>
                <li><a href='https://www.linkedin.com/in/austine-mark-abb7282aa'><img src={`${process.env.PUBLIC_URL}/images/linkedinBlack.png`} alt='ln'/></a></li>
                </ul>
                <p>©2024 - austine mark</p>
            </div>
        </div>
    )
}