import { createStore, combineReducers } from 'redux';
import { newsReducer, projectsReducer, blogsReducer, learnReducer,testimsReducer} from './components/reducer';

const  rootReducer = combineReducers({
    news: newsReducer,
    projects: projectsReducer,
    blogs: blogsReducer,
    learn: learnReducer,
    testims: testimsReducer,
})

const store = createStore(rootReducer)

export default store;