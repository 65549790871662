import './Testimonials.css'
import './Footer.css'
import {React, useEffect, useState} from "react";
import { setTestims } from './actions';
import { connect } from 'react-redux'
import axios from 'axios';
import { Helmet } from 'react-helmet'


 function Testimonials({testims, setTestims}){
  const [isError, setError] = useState(false)
  const [isLoading, setLoading] = useState(false)

  useEffect(()=>{
    const fetchTest = async ()=>{
      try{
        setLoading(true)
        await axios.get('https://us-central1-website-2f4c5.cloudfunctions.net/app/response/testimonials')
        .then(res=>{
          setLoading(false)
          if(res.status === 200){
          const data = res.data
          setTestims(data) 
          }else{
            setError(true)
          }

        })
      }catch(err){
        setLoading(false)
        setError(true)
        console.error('Error:',err)
      }
    }
    if(!testims.length){
      fetchTest()
      }
  },[testims, setTestims])
  console.log(testims)
  return(
    <div className='testimonialCont'>
        <Helmet>
                <title>{"markupDevSolutions(A.M)-blogs"}</title>
                <meta name="description" content="Explore client testimonials on Austine Mark's web development expertise. Dive into success stories and discover why MarkupDev Solutions is the trusted choice for seamless collaborations and transformative website development experiences" />
                <meta name="keywords" content="Discover firsthand client testimonials highlighting Austine Mark's web development expertise at MarkupDev Solutions. Dive into success stories and explore why clients choose us for seamless collaborations, transformative website development experiences, and trusted, positive outcomes." />
                <meta name="google-adsense-account" content="ca-pub-3619400689446319"/>
        </Helmet>
        <h1>Testimonials page</h1>
        {isLoading && <div className='testimsLoading'></div>}
        {isError && <h2 id='testimError'>An error occured try reloading the page</h2>}
        <ul className='testimonials'>
           {testims && testims.map(testim=>(
            <li key={testim.name}>
                <div className='testim'>
                  <img src={testim.image} alt='pic'/>
                  <p> <img src={`${process.env.PUBLIC_URL}/images/startQuote.png`} alt={''}/>{testim.testimonial} 
                  <img src={`${process.env.PUBLIC_URL}/images/endQuote.png`} alt={''}/></p>
                  <h4>{testim.name}</h4>
                  <h5>{testim.company}</h5>
                  <h6>{testim.contact}</h6>
                </div>
            </li>
           ))}
        </ul>
        {testims && <div className='footer'>
                <ul>
                <li><a href='https://www.facebook.com/profile.php?id=100083372384528'> <img src={`${process.env.PUBLIC_URL}/images/facebookBlack.png`} alt='fb'/> </a></li>
                <li><a href='https://www.upwork.com/freelancers/~019e9ee0fe1e2a467c'> <img src={`${process.env.PUBLIC_URL}/images/upworkBlack.png`} alt='up'/> </a></li>
                <li><a href='https://www.youtube.com/@markUpDevSolutions'> <img src={`${process.env.PUBLIC_URL}/images/youtubeBlack.png`} alt='yt'/></a></li>
                <li><a href='https://www.linkedin.com/in/austine-mark-abb7282aa'><img src={`${process.env.PUBLIC_URL}/images/linkedinBlack.png`} alt='ln'/></a></li>
                </ul>
                <p>©2024 - austine mark</p>
            </div>}
    </div>
  )
}




const mapStateToProps = (state) => ({
  testims: state.testims,
});

const mapDispatchToProps = {
  setTestims,
};
export default connect(mapStateToProps, mapDispatchToProps)(Testimonials);