import './Tools.css';
import {useState,useEffect, useRef} from 'react';
import { Link} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLearn } from './actions';

export default function Tools(){
    const dispatch = useDispatch();
    const learnTrack = useSelector((state)=> state.learn.value);
    const[isMenuOPen, setMenuOpen] = useState(false)
    const [activeRoute, setActiveRoute] = useState('/')
    const menuRef = useRef(null)
    useEffect(()=>{
        const handleClickOutside = (event)=>{
            if(menuRef.current && !menuRef.current.contains(event.target)){
                dispatch(setLearn(false))
                setMenuOpen(false)
            }
        }
        document.addEventListener('mousedown',handleClickOutside)

        return ()=>{
            document.removeEventListener('mousedown',handleClickOutside)
        }
    },[menuRef])
    const close_menu = ()=>{
        dispatch(setLearn(false))
        setMenuOpen(false)
    }
    const change_menu = ()=>{
        dispatch(setLearn(false))
        setMenuOpen(true)
    }
  return(
      <div className='menu_cont'>
        <img id='app_logo' src={`${process.env.PUBLIC_URL}/images/applogo.png`} alt='applogo'/>
        <img id='menu_but' src={`${process.env.PUBLIC_URL}/images/menu.png`} alt='menu' onClick={change_menu}/>
        {(isMenuOPen || learnTrack) &&(
        <div className='drop_down' ref={menuRef}>
        <ul>
        <li onClick={close_menu}  className={activeRoute === '/' ? 'active' : ''}>
            <Link to="/" id='link' onClick={()=> setActiveRoute('/')}>Home</Link>
        </li>
        <li onClick={close_menu}  className={activeRoute === '/projects' ? 'active' : ''}>
            <Link to="/projects" id='link' onClick={()=> setActiveRoute('/projects')}>projects</Link>
        </li>
        <li onClick={close_menu}  className={activeRoute === '/about' ? 'active' : ''}>
            <Link to="/about" id='link' onClick={()=> setActiveRoute('/about')}>About</Link>
        </li>
        <li onClick={close_menu}  className={activeRoute === '/contact' ? 'active' : ''}>
            <Link to="/contact" id='link' onClick={()=> setActiveRoute('/contact')}>Contact</Link>
        </li>
        <li onClick={close_menu}  className={activeRoute === '/blog' ? 'active' : ''}>
            <Link to="/blog" id='link' onClick={()=> setActiveRoute('/blog')}>blog</Link>
        </li>
        <li onClick={close_menu}  className={activeRoute === '/testimonials' ? 'active' : ''}>
            <Link to="/testimonials" id='link' onClick={()=> setActiveRoute('/testimonials')}>testimonials</Link>
        </li>
        </ul>
        <h3 onClick={close_menu}  className={activeRoute === '/terms' ? 'active' : ''}>
            <Link to="/terms" id='link2' onClick={()=> setActiveRoute('/terms')}>Terms & Conditions</Link>
        </h3>
        <h4>
            ©austine_mark
        </h4>
        </div>
       )}
       </div>
    );

  }
