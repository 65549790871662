import './Hom.css'
//import '../index.css'
import { useEffect,useState,} from 'react'
import axios from 'axios'
import { Link} from 'react-router-dom';
import { connect, useDispatch} from 'react-redux';
import { setNews, setLearn } from './actions';
import { Helmet } from 'react-helmet'


function Hom({news, setNews}){
    //working with send email container
    const dispatch = useDispatch();
     const [newStatus, setNewStatus] = useState(false)
     const [isLoading, setLoading] = useState(false)
     useEffect(()=>{
        const fetchNews = async ()=>{
            try{
                setNewStatus('Loading new Updates....')
                setLoading(true)
                await axios.get('https://us-central1-website-2f4c5.cloudfunctions.net/app/response/News')
                .then((res)=>{
                    if(res.status === 200){
                        setNewStatus(false)
                        const data = res.data.Message 
                        setNews(data)
                    }else{ 
                       setNewStatus('Internal server error') 

                }})
            }catch(error){
                console.error("Error fetching data: ",error)
                setNewStatus('Error loading updates check internet connection...')
            }finally{
                setLoading(false)
            }
        }
        if(!news.length){
            fetchNews()
        }
        },[news,setNews])

        const handleLearn = ()=>{
          dispatch(setLearn(true))
        }

    return(
    <div  className='homeCont'>
         <Helmet>
            <title>{"markupDevSolutions(A.M)"}</title>
            <meta
                name="description"
                content="markupDev solutions is a portfolio and blog site, created by Austine Mark a web developer and also a computer science student at murang'a university. The blogs in the websites is used for educational purposes anad the projects to showcase my work to potential clients"/>
            <meta
                name="keywords"
                content="web developer,web,react,express,html, css, JavaScript,computer, science,developer,blog,projects,dev,solutions,markupDev"/>
            <meta name="google-adsense-account" content="ca-pub-3619400689446319"/>
        </Helmet>
        <div className='links'>
            <Link id='link' to="/projects">my work</Link>
            <Link id='link' to="/blog">blog</Link>
        </div>
        <div className='newsCont'>
            <h3>What's New</h3>
           {isLoading && <div id='news_progress'></div>}
             {newStatus&& <p>{news}</p>}
            {news && <p dangerouslySetInnerHTML={{ __html: news}}/>}
        </div>
        
        <div className='myScreen'>
        <h2>Web and app developer</h2>
        <p> Hello am Austine Mark, A passionate Web and app developer, 
        let's create a digital experience that leaves an impact.  Ready to elevate your online presence? Let's dive in!</p>
        <ul className='contactList'>
            <li><a href='https://www.facebook.com/profile.php?id=100083372384528'> <img src={`${process.env.PUBLIC_URL}/images/facebookWhite.png`} alt='fb'/> </a></li>
            <li><a href='https://www.upwork.com/freelancers/~019e9ee0fe1e2a467c'> <img src={`${process.env.PUBLIC_URL}/images/upworkWhite.png`} alt='up'/> </a></li>
            <li><a href='https://www.youtube.com/@markUpDevSolutions'> <img src={`${process.env.PUBLIC_URL}/images/youtubeWhite.png`} alt='yt'/></a></li>
            <li><a href='https://www.linkedin.com/in/austine-mark-abb7282aa'><img src={`${process.env.PUBLIC_URL}/images/linkedinWhite.png`} alt='ln'/></a></li>
        </ul>
        </div>
          <button id='learnMore' onClick={handleLearn}>explore more</button>
          <img id='meImage' src={`${process.env.PUBLIC_URL}/images/meImage.png`} alt='meImgae'/>
          <div className='serviceCont'>
            <h4>SERVICES: </h4>
            <ul id='servicesList'>
               <li>Front-end services: Html, css, JavaScript, React, Vite</li>
               <li>Small Back-end: node.js, express.js</li>
               <li>Lightweight Andoid app: Javascript, React-native, expo </li>
               <li>Bug fixing</li>
            </ul>
            <Link to="/contact" id='hireMe'>hire me</Link>
            <p>@2024-austine mark</p>
          </div>
    </div>
    )
}

const mapStateToProps = (state) => ({
    news : state.news,
  });
  
  const mapDispatchToProps = {
    setNews,
  };
export default connect(mapStateToProps, mapDispatchToProps)(Hom);