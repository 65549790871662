import axios from 'axios'
import './Proj.css'
import React  from 'react'
import { useEffect,useState} from 'react'
import { connect } from 'react-redux'
import { setProjects } from './actions'
import { Helmet } from 'react-helmet'


function Proj({projects,setProjects}){
    const [projectStatus, setProjectStatus] = useState(false)
    const [doc, setDoc] = useState(null)
    const[istranslate, setranslate] = useState(0)
    const [isImages, setImages] = useState(false)
    const [zoomLevel, setzoomLevel ] = useState(1.0)
    const [isLoading, setLoading] = useState(false)
    
    useEffect(()=>{
        const fetchProject = async ()=>{
            try{
                setProjectStatus('loading projects.....')
                setLoading(true)
                await axios.get('https://us-central1-website-2f4c5.cloudfunctions.net/app/response/Projects')
                .then((res)=>{
                    if(res.status === 200){
                        const data = res.data
                        setProjectStatus('Projects found click on any projects on the bar to Show details....')
                        setProjects(data)
                    }else{
                        setProjectStatus('Server Error......')
                    }
                })
            }catch(error){
                console.log(error)
                setProjectStatus('Error loading Projects...')
            }
            finally{
                setLoading(false)
            }
        }
        if(!projects.length){
        fetchProject()
        }
    },[projects,setProjects])
    
    const renderImages = (proj)=>{
        setImages(true)
        setProjectStatus(false)
        setDoc(proj.id)
    }
    
    const handleZoom_in = ()=>{
        if(zoomLevel >= 0.1){
            setzoomLevel(zoomLevel -0.1)
            setranslate(istranslate - 10)
        }
        console.log(istranslate)
    }
    const handleZoom_out = ()=>{
        if(zoomLevel <= 2){
            setzoomLevel(zoomLevel +0.1)
            setranslate(istranslate +10)
        }
    }
    return(
        <div className='proj_cont'>
            <Helmet>
                <title>{"markupDevSolutions(A.M)-projects"}</title>
                <meta name="description" content="Austines best projects you can view if you're looking for  a skilled developer to work with. Th projects is used to target potential clients who might be interested to know more about me [markupDevSolutions completed projects]" />
                <meta name="google-adsense-account" content="ca-pub-3619400689446319"/>
                <meta name="keywords" content={projects.map(proj=>proj.id).join(', ')} />
            </Helmet>
            <h2 id='project_title'>My Compeleted Projects</h2>
            <div id='projects_list'>
                <ul>
                {projects.map((proj=>(<li key={proj.id} style={{color:(doc &&(doc === proj.id) ? '#ff0000': '#000000'),cursor:'pointer'}}
                onClick={()=>renderImages(proj)} > {proj.id}
                </li>
                )
                ) )}
                </ul>
            </div>
                {isLoading && <div id='proj_loading'></div>}
                {projectStatus && <p id='project_status'>{projectStatus}</p>}
            {isImages&& <div id='zoom_cont'> 
            <button onClick={handleZoom_in} id='zoomIn_but'>zoom in {zoomLevel.toFixed(1)} X</button>
            <button onClick={handleZoom_out} id='zoomOut_but'>Zoom out {zoomLevel.toFixed(1)} X</button>
            </div> }
            {}
                {doc && (
                    <div style={{display:'flex'}} id='details_cont'>
                       <p>{projects.find((proj)=> proj.id === doc)?.access}</p>
                        <div  style={{display: 'flex'}}>
                            <img src={projects.find((proj)=> proj.id === doc)?.image1} alt='image1'
                              style={{transform :`scaleX(${zoomLevel}) scaleY(${zoomLevel})`}}/>
                            <img src={projects.find((proj)=> proj.id === doc)?.image2} alt='image2'
                            style={{transform :`scaleX(${zoomLevel}) scaleY(${zoomLevel}) translateX(${istranslate}%)`}} />
                        </div>
    
                    </div>)}
        </div>
    )

}
const mapStateToProps = (state) => ({
    projects: state.projects,
  });
  
  const mapDispatchToProps = {
    setProjects,
  };
export default connect(mapStateToProps, mapDispatchToProps)(Proj);