const newsReducer = (state = [], action) => {
    switch (action.type) {
      case 'SET_NEWS':
        return action.payload;
      default:
        return state;
    }
  };
  const projectsReducer = (state = [], action) => {
    switch (action.type) {
      case 'SET_PROJECTS':
        return action.payload;
      default:
        return state;
    }
  };
  const blogsReducer = (state = [], action) => {
    switch (action.type) {
      case 'SET_BLOGS':
        return action.payload;
      default:
        return state;
    }
  };

  const initialLearn = {
    value: false,
  }
  const learnReducer = (state = initialLearn, action) => {
    switch (action.type) {
      case 'SET_LEARN':
        return {
          ...state,
          value: action.payload,
        };
      default:
        return state;
    }
  };

  const testimsReducer = (state = [], action) => {
    switch (action.type) {
      case 'SET_TESTIMS':
        return action.payload;
      default:
        return state;
    }
  };


export {newsReducer, projectsReducer, blogsReducer, learnReducer, testimsReducer}